<template lang="pug">
.region-type-stamp(:class="{ [size]: true }")
  template(v-if="type === REGION_TYPES.PFD")
    h2.title
      img(src="/img/tag.direct.svg?pod" style="min-width: 32px;")
      | Pod Direct Items
    p.description {{ POD_DIRECT_EXPLANATION_IN_PLURAL }}

  template(v-else-if="type === REGION_TYPES.PFG")
    h2.title
      img(src="/img/tag.grow.svg" style="min-width: 32px;")
      | Pod Grow Items
    p.description {{ POD_GROW_EXPLANATION_IN_PLURAL }}

  template(v-else-if="type === REGION_TYPES.WAREHOUSED")
    h2.title
      img(src="/img/tag.express.svg" style="min-width: 50px;")
      | Pod Express Items
    p.description {{ POD_EXPRESS_EXPLANATION_IN_PLURAL }}
</template>

<script>
import {
  REGION_TYPES,
  POD_EXPRESS_EXPLANATION_IN_PLURAL,
  POD_DIRECT_EXPLANATION_IN_PLURAL,
  POD_GROW_EXPLANATION_IN_PLURAL
} from '~/settings'

export default {
  props: {
    type: {
      type: String,
      required: true,
      validate: val => Object.values(REGION_TYPES).includes(val)
    },

    size: {
      type: String,
      default: 'medium',
      validate: val => ['medium', 'small'].includes(val)
    }
  },

  data () {
    return {
      POD_EXPRESS_EXPLANATION_IN_PLURAL,
      POD_DIRECT_EXPLANATION_IN_PLURAL,
      POD_GROW_EXPLANATION_IN_PLURAL
    }
  },

  computed: {
    REGION_TYPES: () => REGION_TYPES
  }
}
</script>

<style lang="scss" scoped>
.region-type-stamp {
  h2 {
    font-size: 18px;
    font-weight: $--font-weight-bold;
    margin: 0;
  }
  p {
    margin: .5em 0 0 0;
    font-size: 14px;
    line-height: 1.5em;
    color: $--color-text-secondary;
    display: flex;
    align-items: flex-start;
  }
  img {
    margin: .25em .75em 0 0;
  }
  &.small {
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
      margin: .25em 0 0 0;
    }
  }
}
</style>
