<template lang="pug">
  div(
    v-tooltip="{ content: `You can change this information by clicking this field then go to <strong> &quot;Request Product Change&quot; </strong> page.` }"
    @click.capture="beforeRedirect")
    slot
</template>

<script>
export default {
  name: 'RedirectRequestInfoTag',

  props: {
    id: {
      type: [String, Number],
      required: true
    },
    sid: {
      type: [String, Number],
      default: undefined
    }
  },

  methods: {
    beforeRedirect () {
      const isVendorProductFormDirty = this.$preferences.get('$is_vendor_product_form_dirty')
      const isVendorSkuFormDirty = this.$preferences.get('$is_vendor_sku_form_dirty')

      this.$confirm('You can change this information by go to <strong>"Request Product Change"</strong> page.', {
        type: 'warning',
        iconClass: 'el-icon-warning el-icon-custom-color-primary',
        confirmButtonText: (isVendorProductFormDirty || isVendorSkuFormDirty) ? 'Save and Redirect' : 'Redirect',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          if (this.$route?.name === 'vendors-products-id' && isVendorProductFormDirty) {
            this.$bus.$emit('dispatch-action-update-vendor-product-details')
            this.$bus.$on('complete-action-update-vendor-product-details', () => { this.redirect() })
          } else if (this.$route?.name === 'vendors-products-id-skus-sid' && isVendorSkuFormDirty) {
            this.$bus.$emit('dispatch-action-update-vendor-sku-details')
            this.$bus.$on('complete-action-update-vendor-sku-details', () => { this.redirect() })
          } else { this.redirect() }
        })
        .catch(this.$debug.log)
    },

    redirect () {
      this.$router.push({
        name: 'vendors-products-id-request-change',
        params: { id: this.id },
        query: { sid: this.sid }
      })?.catch(this.$debug.log)
    }
  }
}
</script>
<style lang="scss">
.el-message-box__status.el-icon-warning.el-icon-custom-color-primary {
  color: $--color-primary;
}
</style>
