<template lang="pug">
el-dialog(
  custom-class="page__dialog preview-product-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bx-cart-alt

    .page__dialog-info
      .page__dialog-title Add to cart - {{ product.product_name }}
  loading(v-if="fetching" text="Fetching product...")
  .groups(v-else)

    el-alert.mb-3(
      v-if="availableSKUs.length === 0"
      :closable="false"
      type="warning"
      size="small")
      .alert.pf-flex
        .notice(style="flex: 1;") There is no items available for add to cart.

    .group(
      v-for="gk in orderedRegionTypes"
      :key="gk"
      v-if="groups[gk]")

      el-alert.mb-3(
        v-if="gk !== REGION_TYPES.PFG && remainQty(groups[gk]) > 0 && estimatedQuantity(groups[gk]) > 0"
        type="warning"
        size="small"
        :closable="false")
        .alert.pf-flex
          .notice(style="flex: 1;") Please add more cases to your order to meet the minimum number of cases required. This vendor may not fulfill if this minimum is not met. Thank you!
          .counter.ml-1
            strong {{ remainQty(groups[gk]) | number }}
            | &nbsp;{{ remainQty(groups[gk]) > 1 ? 'cases' : 'case' }}
            small more

      region-type-stamp.type(:type="gk" size="small")
      .skus
        .sku(v-for="v, vk in groups[gk]" :key="vk")
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-case-units
              show-cartable
              show-price
              show-promotion
              show-price-change)
          .quantity
            el-input-number.pf-block(
              v-model="quantities[v.variants_region_id]"
              :min="0"
              :disabled="!v.cartable"
              size="small")
    .group.pre-order(v-if="comingSoonSKUs.length && showPreOrder")
      .pre-order__header
        .pre-order-title
          i.page__dialog-icon.bx.bx-calendar-check
          h2.title Pre-order Items
        .pre-order-actions
          a.linked(href="#" @click="preOrder") Pre-order now →
      p These items only available for pre-order
      .pre-order__items
        .sku(v-for="v, vk in comingSoonSKUs" :key="vk")
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-price
              show-price-change
              show-case-units)

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="submit"
      :disabled="!submittable || saving") Add to cart

</template>

<script>
import { groupBy, sum, orderBy } from 'lodash'
import { REGION_TYPES, PRODUCT_AVAILABILITY, LIMIT_TYPES } from '~/settings'

import RegionTypeStamp from '~/components/stamps/RegionTypeStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'
export default {
  components: {
    ProductVariantStamp,
    RegionTypeStamp
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      quantities: {},
      variants: [],
      products_region_moqs: [],
      fetching: false,
      saving: false,
      showPreOrder: true,
      REGION_TYPES
    }
  },

  fetch () {
    return this.fetch()
  },

  computed: {
    orderedRegionTypes () {
      return [
        REGION_TYPES.PFD,
        REGION_TYPES.WAREHOUSED,
        REGION_TYPES.PFG
      ]
    },

    comingSoonSKUs () {
      if (!this.variants) return []
      return Object.values(this.variants).filter(i => i.availability === PRODUCT_AVAILABILITY.COMING_SOON)
    },

    availableSKUs () {
      if (!this.variants) return []
      return Object.values(this.variants).filter(i => i.availability !== PRODUCT_AVAILABILITY.COMING_SOON)
    },

    groups () {
      const items = this.availableSKUs
        .map(i => ({ ...i, FE_region_type: i.is_pod_grow_variant ? REGION_TYPES.PFG : i.region_type }))
      const sorted = orderBy(items, ['cartable'], ['desc'])

      return groupBy(sorted, 'FE_region_type')
    },

    submittable () {
      const listPfdItems = this.availableSKUs.filter(item => item.region_type === REGION_TYPES.PFD && item.cartable && !item.is_pod_grow_variant)
      if (listPfdItems.length > 0) {
        if (this.estimatedQuantity(listPfdItems) > 0 && this.remainQty(listPfdItems) > 0) {
          return false
        }
      }
      return sum(Object.values(this.quantities))
    },

    trackZone () {
      switch (this.$route?.name) {
        case '_brand':
          return 'BRAND_DETAILS_PRODUCT_CARD_ADD_TO_CART_POPUP'
        case 'products':
          return 'CATALOG_PRODUCT_CARD_ADD_TO_CART_POPUP'
        case 'order-guide':
          return 'ORDER_GUIDE_RECOMMENDED_SLIDE_PRODUCT_CARD_ADD_TO_CART_POPUP'
        case '_product':
          return 'PRODUCT_DETAILS_PAGE_BUYER_ARE_ALSO_SHOPPING_FOR'
        default:
          return `MAYBE_MISSING_FROM_ROUTE_${this.$route?.name}`
      }
    }
  },

  watch: {
    'product.variants': {
      immediate: true,
      handler (val = {}) {
        this.quantities = Object.values(val)
          .reduce((res, i) => ({
            ...res,
            [i.variants_region_id]: 0
          }), {})
      }
    }
  },

  methods: {
    fetch () {
      this.fetching = true
      this.$api.catalog.getProductDetails(this.product.product_id)
        .then((res) => {
          this.products_region_moqs = res.products_region_moqs
          this.variants = res.variants
          this.quantities = Object.values(res.variants)
          .reduce((res, i) => ({
            ...res,
            [i.variants_region_id]: 0
          }), {})
        })
        .finally(() => { this.fetching = false })
    },

    remainQty (variants) {
      const listMoqItems = variants.filter(i => i.limit_type === LIMIT_TYPES.MOQ && i.cartable && !i.is_pod_grow_variant)
      let moq = 1
      if (listMoqItems.length) {
        moq = (this.products_region_moqs || [])
          .find(i => i.region_id === listMoqItems[0].region_id)?.moq || 1
      }
      return moq - this.estimatedQuantity(variants)
    },

    estimatedQuantity (variants) {
      const addedQuantity = this.$store.getters['cart/addedQuantityByProduct']({ variant: variants[0] })
      const totalQuantity = variants.reduce((sum, v) => sum + this.quantities[v.variants_region_id], 0)
      return addedQuantity + totalQuantity
    },

    submit () {
      this.$amplitude.track('CLICK_ADD_TO_CART', {
        zone: this.trackZone,
        quantity: Object.values(this.quantities).reduce((sum, v) => sum + v, 0)
      })
      this.saving = true
      const variants = Object
        .values(this.variants)
        .map(i => ({ ...i, promotions: i._promotions }))
        .filter(i => this.quantities[i.variants_region_id] > 0)
      const cartItems = Object
        .keys(this.quantities)
        .filter(id => this.quantities[id] > 0)
        .map(id => ({
          variants_region_id: id,
          quantity: this.quantities[id]
        }))
      return this.$workflow
        .addMultipleItemToCart({
          parent: this,
          product: { ...this.product, variants: this.variants },
          variants,
          cartItems,
          events: {
            closed: () => {
              this.saving = false
            }
          }
        })
        .then(res => {
          this.$emit('done', res)
          this.$emit('close')
        })
        .catch(this.$debug.log)
        .finally(() => {
          this.saving = false
        })
    },
    preOrder () {
      return this.$workflow.requestPreOrder({
        parent: this,
        product: { ...this.product, variants: this.variants }
      })
      .then(() => {
        this.showPreOrder = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .preview-product-modal {
    max-width: 600px;
  }
}
.alert {
  .counter {
    font-size: 2em;
    line-height: 1em;
    font-weight: $--font-weight-bold;
    text-align: right;
    small {
      display: block;
      font-size: .5em;
      font-weight: $--font-weight-normal;
    }
  }
}
.page__dialog-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.groups {
  .group {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    .skus {
      margin-top: .5em;
      > .sku {
        display: flex;
        padding: .5em 0;
        align-items: center;
        &:not(:last-child) {
          border-bottom: $--border-light;
        }
        > * {
          min-width: 0;
        }
        > .preview {
          flex: 1;
          ::v-deep {
            .variant-name {
              font-weight: $--font-weight-bold;
            }
          }
        }
        > .quantity {
          flex: 0 120px;
        }
      }
    }
    .error {
      font-size: 13px;
      line-height: 1.5em;
      padding-top: 5px;
      color: $--color-danger;
      text-align: center;
    }
    &.pre-order {
      background-color: #fbf8f4;
      padding: 12px;
      ::v-deep {
        .product-variant-stamp {
          background-color: #fbf8f4;
        }
      }
      .pre-order__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pre-order-title {
          display: flex;
          align-items: center;
          h2 {
            margin: 0 0 0 0.75rem;
            font-size: 18px;
            font-weight: $--font-weight-bold;
          }
        }
      }
      .pre-order__items {
        margin-top: .5em;
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
      }
      p {
        margin: 0.25em 0 0 0;
        font-size: 13px;
        line-height: 1.5em;
        color: $--color-text-secondary;
      }
    }
  }
}
</style>
