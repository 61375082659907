<template lang="pug">
el-dialog.buyer-mobile-app-modal(
  visible
  @close="close")
  .background
    .blob(v-image="'/img/decor.blob.svg?pod'")
    img.cube.yellow(src="/img/isometric.cube-yellow.svg")
    img.cube.white(src="/img/isometric.cube-white.svg")
    img.screenshot(src="/img/app.screenshot.webp")

  .content
    h1 Hey Pod is now live!
    p The only full-service wholesale marketplace now available at your fingertips. Download on the App Store and the Google Play Store.

    .links
      a(:href="appstore" target="_blank" title="Download on the Appstore")
        img(src="/img/appstore.webp")
      a(:href="playstore" target="_blank" title="Download on Google Play")
        img(src="/img/playstore.webp")

    el-checkbox.toggler(v-model="hidden" label="Never show this again")
</template>

<script>
import { BUYER_APPSTORE_URL, BUYER_PLAYSTORE_URL } from '~/settings'

export default {
  data () {
    return {
      hidden: false
    }
  },

  computed: {
    appstore: () => BUYER_APPSTORE_URL,
    playstore: () => BUYER_PLAYSTORE_URL
  },

  methods: {
    close () {
      this.$emit('done', this.hidden)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.buyer-mobile-app-modal {
  padding: 0;
  ::v-deep {
    .el-dialog {
      border-radius: $--radius-sm;
      max-width: 520px;
      position: relative;
      @include media(xs-only) {
        max-width: 320px;
      }
      &__header {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
      }
      &__body {
        padding: 0;
      }
    }
  }
}
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .blob {
    background-position: bottom right;
    background-repeat: no-repeat;
    width: 86%;
    height: 260px;
    background-size: 190%;
    @include media(sm) {
      width: 44%;
      height: 220px;
      background-size: 190%;
    }
  }
  .screenshot {
    position: absolute;
    top: 10px;
    left: 20%;
    @include media(sm) {
      left: 24px;
    }
  }
  .cube {
    &.white {
      position: absolute;
      bottom: -25px;
      right: -44px;
    }
    &.yellow {
      position: absolute;
      width: 36px;
      top: 64px;
      left: 248px;
      @include media(sm) {
        top: 36px;
        left: 210px;
      }
    }
  }
}
.content {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  padding: 5.5em 2.5em 2.5em 47%;
  word-break: break-word;
  @include media(xs-only) {
    text-align: center;
    padding: 24em 2em 2em;
  }
  h1 {
    font-size: 24px;
    line-height: 1.25em;
  }
  p {
    font-size: 13px;
    color: $--color-text-secondary;
    line-height: 1.5em;
  }
  .links {
    margin-top: 24px;
    font-size: 0;
    a {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .toggler {
    font-size: 13px;
    margin-top: 2em;
    ::v-deep {
      .el-checkbox {
        &__label {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
