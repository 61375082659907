<template lang="pug">
.direct-tag
  img.icon(src="/img/tag.direct.svg?pod")
  span(v-if="explained") {{ POD_DIRECT_EXPLANATION }}
</template>

<script>
import { POD_DIRECT_EXPLANATION } from '~/settings'
export default {
  props: {
    explained: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { POD_DIRECT_EXPLANATION }
  }
}
</script>

<style lang="scss" scoped>
.direct-tag {
  font-size: 12px;
  color: $--color-text-secondary;
  display: inline-flex;
  align-items: flex-start;
  > *:not(:last-child) {
    margin-right: 1em;
  }
}
</style>
