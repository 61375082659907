import { update, merge } from '@/utilities/store'
import { PROMOTION_TYPES, PRODUCT_AVAILABILITY, PROP_65_REGIONS } from '~/settings'

export const DEFAULT_DATA = {
  barcode_types: [
    { id: 'Barcodes::Upc', name: 'UPC' },
    { id: 'Barcodes::Ean', name: 'EAN' }
  ],
  promotion_types: [
    { id: PROMOTION_TYPES.BUY_IN, name: 'Buy-in' },
    { id: PROMOTION_TYPES.TPR, name: 'TPR' },
    { id: PROMOTION_TYPES.SHORT_DATE, name: 'Short-dated' }
  ],
  product_variant_availabilities: [
    {
      id: PRODUCT_AVAILABILITY.IN_STOCK,
      name: 'In Stock',
      samplable: true,
      cartable: true
    },
    {
      id: PRODUCT_AVAILABILITY.COMING_SOON,
      name: 'Launching Soon',
      samplable: true,
      cartable: false
    },
    {
      id: PRODUCT_AVAILABILITY.SOLD_OUT,
      name: 'Out of Stock',
      samplable: true,
      cartable: false
    }
  ],
  weekdays: [
    { id: 'monday', name: 'Monday' },
    { id: 'tuesday', name: 'Tuesday' },
    { id: 'wednesday', name: 'Wednesday' },
    { id: 'thursday', name: 'Thursday' },
    { id: 'friday', name: 'Friday' },
    { id: 'saturday', name: 'Saturday' },
    { id: 'sunday', name: 'Sunday' }
  ]
}

export const state = () => ({
  data: {}
})

export const getters = {
  data: s => s.data,

  notiGroups: s => s.data.notification_groups || [],
  vendorEmailGroups: s => s.data.vendor_email_groups || [],
  brokerEmailGroups: s => s.data.broker_email_groups || [],
  emailGroups: s => s.data.buyer_email_groups || [],

  countries: s => s.data.countries,
  addressStates: s => s.data.address_states || [],
  brandTierCommissions: s => s.data.tier_commissions || [],
  stateFees: s => s.data.state_fee_names || [],

  barcodeTypes: s => s.data.barcode_types || [],
  barcodeTypeById: (s, g) => (id, key) => {
    const barCode = g.barcodeTypes.find(e => e.id === id)

    if (!barCode) return null

    return key ? barCode[key] : barCode
  },

  productVariantAvailabilities: s => s.data.product_variant_availabilities || [],
  productVariantAvailabilityById: (s, g) =>
    id => g.productVariantAvailabilities
      .find(i => i.id === id),

  productCategories: (s, g) => (s.data.product_categories || [])
    .map(i => ({
      ...i,
      product_types: g.productTypes.filter(t => t.product_category_id === i.id)
    })),
  productCategoryById: (s, g) => id => g.productCategories.find(i => i.id === id),

  containerTypes: (s) => s.data.container_types || [],

  productQualities: s => s.data.product_qualities || [],
  productQualityById: (s, g) => id => g.productQualities.find(i => i.id === id),

  productVolumeUnits: s => s.data.product_volume_units || [],
  productVolumeUnitById: (s, g) => id => g.productVolumeUnits.find(i => i.id === id),

  productTypes: s => s.data.product_types || [],
  productTypeById: (s, g) => id => g.productTypes.find(i => i.id === id),

  productTags: s => s.data.product_tags || [],
  productTagById: (s, g) => id => g.productTags.find(i => i.id === id),

  productRecommendationGroup: s => s.data.product_recommendation_group || null,

  packageSizes: s => {
    return s.data.package_sizes
      ? [...s.data.package_sizes].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      : []
  },
  packageSizeById: (s, g) => id => g.packageSizes.find(i => i.id === id),

  inboundInventoryDeliveryMethods: s => s.data.inbound_inventory_delivery_methods || [],

  routes: s => s.data.routes || [],
  regions: s => s.data.regions || [],
  blacklistRegions: s => s.data?.black_list_regions || [],
  blacklistRegionsIds: s => s.data?.black_list_regions?.map(i => i.region_id) || [],
  storageShelfLifeConditions: (s, g) => g.data.storage_shelf_life_conditions || [],
  retailShelfLifeConditions: (s, g) => g.data.retail_shelf_life_conditions || [],
  getStroreShelfLifeById: (s) => (id, key) => {
    const sl = s.data.storage_shelf_life_conditions?.find(e => e.id === id)

    if (!sl) return null

    return key ? sl[key] : sl
  },
  regionNameByIdObj: (s) => {
    return s.data.regions?.reduce((res, cur) => {
      res[cur.id] = cur.name

      return res
    }, {})
  },
  getRegionById: (s) => (id, key) => {
    const r = s.data.regions?.find(e => e.id === id)

    if (!r) return null

    return key ? r[key] : r
  },
  getRegionByName: (s) => (name) => s.data.regions.find(e => e.name === name),
  getRegionByAbbrName: (s) => (name) => s.data.regions.find(e => e.abbreviated_name === name),
  props65Ids: (s) => s.data.regions.filter(e => PROP_65_REGIONS.includes(e.abbreviated_name)).map(e => e.id),
  directRegions: (s, g) => g.regions.filter(i => i.type === 'Regions::Direct'),
  directRegionIds: (s, g) => g.directRegions.map(i => i.id),
  warehousedRegions: (s, g) => g.regions.filter(i => i.type === 'Regions::Warehoused'),
  warehousedRegionIds: (s, g) => g.warehousedRegions.map(i => i.id),

  warehouses: (s) => s.data.warehouses || [],
  activeWarehouses: (s) => s.data.warehouses.filter(e => e.state === 'active') || [],
  getWarehouseById: (s) => (id, key) => {
    const wh = s.data.warehouses?.find(e => e.id === id)

    if (!wh) return null

    return key ? wh[key] : wh
  },

  storeTypes: s => s.data.store_types || [],
  storeSizes: s => s.data.store_sizes || [],
  timeZones: s => s.data.time_zones || [],
  carriers: s => s.data.carriers || [],
  shippoParcelValidDistanceUnits: s => s.data.shippo_parcel_valid_distance_units || [],
  shippoParcelValidMassUnits: s => s.data.shippo_parcel_valid_mass_units || [],
  getCountryId: (_, g) => (id) => g.countries.find(c => c.id === id) || {},
  getStateById: (_, g) => (id) => g.addressStates.find(i => i.id === id) || {},
  hasNewInventory: (s) => s.data?.badges?.inventories?.new_item,
  numberOfPodPlanned: (s, g, rt, rg) => {
    const vendorCompanySelected = rg['broker/vendorCompanySelected']

    if (vendorCompanySelected) {
      return vendorCompanySelected?.badges?.inbound_inventories?.pod_planned || null
    }

    return s.data.badges?.inbound_inventories?.pod_planned || null
  },
  useKailuaApi: (s) => s.data.admin_setting?.use_kailua_api || false,

  mondayFormOptions: (s, g) => g.data?.monday_form_options || {},
  weeklyPromo: (s, g) => g.data.weekly_ad || null,
  remoteSettings: (s, g) => g.data.remote_settings
}

export const mutations = {
  update,
  merge,
  default (state) {
    state.data = DEFAULT_DATA
  }
}

export const actions = {
  sync ({ commit, dispatch }) {
    commit('default')
    return Promise
      .all([
        dispatch('getMasterData'),
        dispatch('getProductCategories'),
        dispatch('getFilterFields'),
        dispatch('getAddressStates')
      ])
  },

  clientSync ({ dispatch, rootGetters }) {
    this.$rum.resync()
    this.$amplitude.sync()

    const user = rootGetters['authentication/user']
    const role = rootGetters['authentication/role']

    if (!user || !this.$api) return null

    const showDialogIfNeeded = () => {
      if (this.$moment().isSameOrBefore(this.$moment('2024-08-01'))) {
        this.$workflow.showVendorRetailerReferralInvitationIfNeeded()
      } else {
        // this.$workflow.showVendorCapitalInvitationIfNeeded()
        this.$workflow.showVendorShopifyInvitationIfNeeded()
      }

      this.$workflow.showBuyerAppInvitationIfNeeded()
      this.$workflow.showPrivacyPolicyChangesIfNeeded()
    }

    if (!window.$nuxt?.$root) {
      window.onNuxtReady(() => {
        showDialogIfNeeded()
      })
    } else {
      showDialogIfNeeded()
    }

    const tasks = [
      dispatch('getMasterData'),
      dispatch('authentication/getAnnouncements', undefined, { root: true })
    ]

    switch (role) {
      case 'head_buyer':
        tasks.push(dispatch('getWeeklyPromo'))
        break

      case 'buyer':
        tasks.push(dispatch('authentication/getBuyerStores', undefined, { root: true }))
        tasks.push(dispatch('authentication/getPaymentInformation', undefined, { root: true }))
        tasks.push(dispatch('cart/fetch', undefined, { root: true }))
        tasks.push(dispatch('getWeeklyPromo'))
        break

      case 'logistics_partner':
        break

      case 'vendor':
        tasks.push(dispatch('authentication/getPaymentInformation', undefined, { root: true }))
        tasks.push(dispatch('notifications/connect', undefined, { root: true }))
        break
    }

    return Promise.all(tasks)
  },

  getMasterData ({ state, commit }) {
    return this.$api.common.master()
      .then(res => {
        commit('update', {
          data: {
            ...state.data,
            ...(res?.master_data || {})
          }
        })
      })
  },

  getAddressStates ({ state, commit }) {
    return this.$api.catalog.fetchAddressStates()
      .then(res => {
        commit('update', {
          data: {
            ...state.data,
            address_states: res.states
          }
        })
      })
  },

  getProductCategories ({ state, commit }) {
    return this.$api.catalog.fetchProductCategories()
      .then(res => {
        commit('update', {
          data: {
            ...state.data,
            product_categories: res.categories || []
          }
        })
      })
  },

  getFilterFields ({ state, commit }) {
    return this.$api.catalog.fetchFilterFields()
      .then(res => {
        const { filters = {} } = res
        const { brand = [], product_catalog = [] } = filters
        const productType = brand
          .find(i => i.filter_name === 'product_type')
        const tags = product_catalog
          .find(i => i.filter_name === 'tag_ids')
        const productQualities = product_catalog
          .find(i => i.filter_name === 'product_quality')
        const packageSizes = product_catalog
          .find(i => i.filter_name === 'package_size')

        commit('update', {
          data: {
            ...state.data,
            product_types: (productType?.filter_values || []).map(i => ({ id: i.search, name: i.display })),
            product_tags: (tags?.filter_values || []).map(i => ({ id: i.search, name: i.display })),
            product_qualities: (productQualities?.filter_values || []).map(i => ({ id: i.search, name: i.display })),
            package_sizes: (packageSizes?.filter_values || []).map(i => ({ id: i.search, name: i.display }))
          }
        })
      })
  },

  getWeeklyPromo ({ state, commit, rootGetters }) {
    const apiRole = rootGetters['authentication/apiRole']

    return this.$api[apiRole].getWeeklyPromo()
    .then(res => {
      commit('update', {
        data: {
          ...state.data,
          weekly_ad: res
        }
      })
    })
  }

}
