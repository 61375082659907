// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-400-normal.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-400-normal.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-500-normal.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-500-normal.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-700-normal.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../node_modules/@fontsource/open-sauce-sans/files/open-sauce-sans-latin-700-normal.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Open Sauce Sans\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");unicode-range:u+0030-0039,u+0041-005a,u+0061-007a,u+0020-009f}@font-face{font-display:swap;font-family:\"Open Sauce Sans\";font-style:normal;font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");unicode-range:u+0030-0039,u+0041-005a,u+0061-007a,u+0020-009f}@font-face{font-display:swap;font-family:\"Open Sauce Sans\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");unicode-range:u+0030-0039,u+0041-005a,u+0061-007a,u+0020-009f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
