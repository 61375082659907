<template lang="pug">
.promotions-tag
  el-popover(
    v-if="popoverable"
    trigger="hover"
    width="300"
    popper-class="promotion-popper"
    @after-enter="fetchPromotion")
    .variants(v-if="listVariant.length > 0")
      .variant(v-for="(i,k) in listVariant" :key="k")
        .flex(v-for="(p, j) in i.promotions" :key="j")
          .image
            .contain(v-image="i.imageUrl")
          .preview
            .name
              span.sku.pf-ellipsis {{ i.name }}
              span.promotion-type.ml-2
                template {{ p.promotion_type | promotionType }}
                help-tag(
                  v-if="p.promotion_type === PROMOTION_TYPES.TPR || p.promotion_type === SHORT_PROMOTION_TYPES.TPR"
                  :tooltip="p.promotion_type | promotionTypeHelpText")
            .price
              span.current.promoted {{ i.case_price_cents | promotion-price(p) }}
              span.old {{ i.case_price_cents | cents }}

            .case-minimum(v-if="p.minimum_num_case > 1")
              small Applicable with a minimum purchase of&nbsp;
                strong.value {{ p.minimum_num_case | number }}
                | &nbsp;cases

            .expiry-date(v-if="p.promotion_type === SHORT_PROMOTION_TYPES.SHORT_DATE")
              small SKU expiry date:&nbsp;
                strong.value {{ p.sku_expiry_date | date }}

            .case-limit(v-if="p.case_limit")
              template(v-if="p.promotion_type === SHORT_PROMOTION_TYPES.BUY_IN")
                small Limited to first&nbsp;
                  strong.value {{ p.case_limit | number }}
                  | &nbsp;case{{ p.case_limit > 1 ? 's' : '' }} of the first order

              template(v-else)
                small First&nbsp;
                  strong.value {{ p.case_limit | number }}
                  | &nbsp;case{{ p.case_limit > 1 ? 's' : '' }} only
    loading.no-padding(v-else text="Loading...")
    .promotion-tag(v-if="popoverable" :type="normalized" :style="{ fontSize }" slot="reference") {{ type | cap }}
  template(v-else)
    .promotion-tag(:type="normalized" :style="{ fontSize }") {{ type | cap }}
</template>

<script>
import { snakeCase, values, cloneDeep } from 'lodash'
import { PROMOTION_TYPES, SHORT_PROMOTION_TYPES } from '~/settings'

export default {
  name: 'PromotionType',

  props: {
    type: {
      type: String,
      default: 'None'
    },

    showPopover: {
      type: Boolean,
      default: true
    },

    variants: {
      type: Array,
      default: () => []
    },

    productId: {
      type: Number,
      default: null
    },

    fontSize: {
      type: String,
      default: '13px'
    }
  },

  data () {
    return {
      PROMOTION_TYPES,
      SHORT_PROMOTION_TYPES,
      listVariant: cloneDeep(this.variants),
      loading: false
    }
  },

  computed: {
    normalized () {
      return snakeCase(this.type)
    },

    popoverable () {
      return this.showPopover
    }
  },

  methods: {
    fetchPromotion () {
      if (this.listVariant.length > 0) return
      this.$api.catalog.getProductDetails(this.productId)
        .then((res) => {
          this.listVariant = values(res.variants).filter(v => {
            v.imageUrl = v.image_urls ? v.image_urls.product : v.photo_url
            return v.promotions && v.promotions.length > 0
          })
        })
    }
  }
}
</script>

<style lang="scss">
.el-popover {
  word-break: unset;
  text-align: unset
}

.promotion-tag {
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 11px !important;
  line-height: 100%;
  letter-spacing: 0.02em;
  display: inline-block;
  white-space: nowrap;
  font-weight: $--font-weight-bold;
  color: white;
  user-select: none;
  &[type=promotions] {
    background: #e24960;
  }
  &[type=tpr] {
    background: $--color-success;
  }
  &[type=buy_in] {
    background: $--color-primary;
  }
  &[type=short_dated] {
    background: #fbb122;
  }
}

.promotion-popper {
  padding: 8px;
  .variant {
    position: relative;
    overflow: hidden;
    font-size: 12px;
    &:not(:first-child) {
      border-top: $--border-light;
    }
    .image {
      flex: 0 64px;
      padding: 6px;
      .contain {
        width: 100%;
        height: 0;
        padding-top: 100%;
        border-radius: $--radius;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    .preview {
      padding: 6px 6px 6px 2px;
      width: 100%;
      .name {
        color: $--color-text-regular;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        .sku {
          max-width: 120px;
        }
        .promotion-type {
          color: #f3a333;
          font-weight: $--font-weight-bold;
          margin-left: 5px;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }
      .price {
        .current {
          font-weight: $--font-weight-bold;
          font-size: 13px;
          &.promoted {
            color: $--color-primary;
          }
        }
        .old {
          font-size: 11px;
          color: $--color-text-secondary;
          text-decoration: line-through;
          margin-left: 8px;
        }
      }
      .case-minimum,
      .expiry-date,
      .case-limit {
        small {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
