<template lang="pug">
el-dialog(
  custom-class="page__dialog mov-alert-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="handleClose")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      img(
        v-if="submittable"
        width="36"
        height="36"
        src="/img/pre-qual-marketing-icon.svg"
        alt="Pod Pre-Qualification")
      i.page__dialog-icon.bx.bxs-dollar-circle(v-else)

    .page__dialog-info
      .page__dialog-title(v-text="title")
      .page__dialog-description(v-if="!submittable")
        span Add more cases

  .top-sticky(v-if="missing > 0")
    el-alert(
      type="error"
      size="small"
      :closable="false")
      .alert
        .notice Please add more cases to any SKU below to meet the minimum order value required. Your order may not be fulfilled by the brand if the Minimum Order Value is not met.
        .counter
          strong {{ missing | cents }}
          small more

  .groups
    .group
      region-type-stamp.type(
        :type="REGION_TYPES.PFD"
        size="small")

      infinite-fetcher(
        ref="fetcher"
        :fetch="fetchRelatedSkus"
        :paginator="paginator")
        .skus

          // product variants from cart & from previous add to cart action
          .sku(v-for="v, vk in variants" :key="vk")
            .preview
              product-variant-stamp.plain(
                :data="v"
                showImage
                read-only
                show-product
                show-variant-name
                show-price
                show-price-change
                show-case-units
                show-cartable
                show-promotion)
            .quantity
              el-input-number.pf-block(
                v-model="quantities[v.variants_region_id]"
                :min="0"
                :disabled="v.cartable === false"
                size="small")

          // related skus
          loading(v-if="loading")
          .sku(v-for="v, vk in related" :key="`related-${vk}`")
            .preview
              product-variant-stamp.plain(
                :data="v"
                showImage
                read-only
                show-product
                show-variant-name
                show-price
                show-price-change
                show-case-units
                show-cartable
                show-promotion)
            .quantity
              el-input-number.pf-block(
                v-model="quantities[v.variants_region_id]"
                :min="0"
                :disabled="v.cartable === false"
                size="small")

  .bottom-sticky
    el-button(
      :class="{ 'ring': submittable }"
      type="primary"
      @click="submit"
      :disabled="!submittable || saving") Update cart
</template>

<script>
import { uniqBy, sumBy, pick, groupBy, toPairs, map } from 'lodash'
import { REGION_TYPES } from '~/settings'
import { calculateItemPrice } from '~/utilities/promotions'

import InfiniteFetcher from '~/components/InfiniteFetcher'
import RegionTypeStamp from '~/components/stamps/RegionTypeStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'

export default {
  components: {
    InfiniteFetcher,
    RegionTypeStamp,
    ProductVariantStamp
  },

  props: {
    product: { type: Object, default: null },
    variant: { type: Object, required: true },
    quantity: { type: Number, required: true },
    mov: { type: Object, default: null },
    incomingPfdItems: { type: Array, default: () => [] },
    preOrderItems: { type: Array, default: () => [] }
  },

  data () {
    return {
      loading: true,
      saving: false,
      related: [],
      quantities: {},
      paginator: {}
    }
  },

  computed: {
    REGION_TYPES: () => REGION_TYPES,

    title () {
      const preTitle = this.submittable
        ? 'Minimum Order Value is met!'
        : 'Minimum Order Value is not met'

      if (this.mov)
        return `${preTitle} - ${this.mov.title}`
      const mov = this.$store.getters['cart/movs']
        .find(i => i.company === this.variant?.vendor_company_id)
      if (mov)
        return `${preTitle} - ${mov.title}`
      return preTitle
    },

    cartableAvailabilities () {
      return this.$store
        .getters['master/productVariantAvailabilities']
        .filter(i => i.cartable)
        .map(i => i.id)
    },

    variants () {
      const cartVariants = this.$store.getters['cart/items']
        .filter(i =>
          i.vendor_company_id === this.variant.vendor_company_id &&
          i.region_type === REGION_TYPES.PFD && !i.is_pod_grow_variant)

      const remakeVariants = map(toPairs(groupBy([...this.incomingPfdItems, ...cartVariants], 'variants_region_id')), ([variantRegionId, variants]) => {
        if (variants.length === 1) return variants[0]

        const totalQty = variants.reduce((sum, i) => sum + (i.quantity || 0), 0)
        const totalIncomingQty = variants.reduce((sum, i) => sum + (i.incomingQuantity || 0), 0)
        return {
          ...variants[0],
          quantity: totalQty,
          incomingQuantity: totalIncomingQty
        }
      }).sort((a, b) => a.incomingQuantity ? -1 : b.incomingQuantity ? 1 : 0)

      return remakeVariants
    },

    missing () {
      const total =
        sumBy([
          ...this.variants,
          ...this.related
        ].map(i => {
          const quantity = (i.quantity || 0) + this.quantities[i.variants_region_id]
          const item = pick(i, [
            'promotions',
            'case_price_cents',
            'variants_region_id',
            'quantity'
          ])
          item.total = calculateItemPrice(item, quantity)
          return item
        }), 'total')
      return (this.mov?.mov || this.variant?.mov) - total
    },

    submittable () {
      return this.missing <= 0
    }
  },

  watch: {
    variants: {
      immediate: true,
      handler (val = {}) {
        this.quantities = {
          ...this.quantities,
          ...Object.values(val)
            .reduce((res, i) => ({
              ...res,
              [i.variants_region_id]: i.incomingQuantity || 0
            }), {})
        }
      }
    },

    related (val = []) {
      this.quantities = {
        ...this.quantities,
        ...val.reduce((res, i) => ({
          ...res,
          [i.variants_region_id]: 0
        }), {})
      }
    }
  },

  mounted () {
    this.loading = true
    this.fetchRelatedSkus()
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    handleTrack (event) {
      this.$amplitude.track(event, { product_id: this.product?.id })
    },

    handleClose () {
      this.handleTrack('CLICK_PRODUCT_DETAIL_ADD_TO_CART_CLOSE_MOQ_MOV_ALERT')
      this.$emit('close')
    },

    fetchRelatedSkus ({ page = 1 } = {}) {
      return this.$api.buyer
        .getProductVariants({
          'q[vendor_company_id]': this.mov
            ? this.mov.company
            : this.product?.vendor_company_id,
          'q[region_type]': this.variant?.region_type,
          'q[ignore_variant_ids]': this.incomingPfdItems.map(i => i.id),
          page
        })
        .then(res => {
          const skus = res?.results.filter(e => !e.is_pod_grow_variant)

          this.paginator = this.$paginators.parse(res)
          this.related = uniqBy([
            ...this.related,
            ...skus
          ], 'id')
        })
    },

    submit () {
      this.$amplitude.track('CLICK_ADD_TO_CART', {
        zone: 'MOV_ALERT_ADD_TO_CART_POPUP',
        quantity: Object.values(this.quantities).reduce((sum, v) => sum + v, 0)
      })
      this.saving = true
      const variants = Object
        .values(this.product?.variants || [])
        .filter(i => this.quantities[i.variants_region_id] > 0)
      const cartItems = Object
        .keys(this.quantities)
        .filter(id => this.quantities[id] > 0)
        .map(id => {
          const preOrderId = this.preOrderItems.find(i => i.variants_region_id === +id)?.id
          return {
            pre_order_line_item_id: preOrderId,
            variants_region_id: id,
            quantity: this.quantities[id]
          }
        })
      return this.$workflow
        .addItemsToCart({
          variants,
          cartItems
        })
        .then(res => {
          this.handleTrack('CLICK_PRODUCT_DETAIL_ADD_TO_CART_UPDATE_CART')
          this.$emit('done', res)
          this.$emit('close')
        })
        .catch(this.$debug.log)
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ring {
  animation: horizontal-shaking 0.4s linear 2;
}

::v-deep {
  .mov-alert-modal {
    max-width: 600px;
  }
}

.page__dialog {
  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-icon {
    color: $--color-warning;
    background: none;
    font-size: 36px;
  }
}

.top-sticky {
  padding: 1em 0;
  margin-top: -1em;
  margin-bottom: .5em;
  position: sticky;
  top: 0;
  z-index: 3;
  background: white;
  .alert {
    display: flex;
    flex-direction: column;
    @include media(md) {
      flex-direction: row;
    }
    > * {
      min-width: 0;
    }
    .notice {
      flex: 1;
    }
    .counter {
      line-height: 1em;
      font-weight: $--font-weight-bold;
      margin: .5em 0 0 0;
      font-size: 1.5em;
      @include media(md) {
        text-align: right;
        font-size: 2em;
        margin: 0 0 0 .5em;
      }
      small {
        font-size: .5em;
        font-weight: $--font-weight-normal;
        margin: 0 0 0 .5em;
        @include media(md) {
          display: block;
          margin: 0;
        }
      }
    }
  }
}
.bottom-sticky {
  position: sticky;
  bottom: 0;
  z-index: 3;
  background: white;
  padding: 1em 0;
  margin-bottom: -1.5em;
  text-align: right;
}
.groups {
  .group {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    .skus {
      margin-top: .5em;
      > .sku {
        display: flex;
        padding: .5em 0;
        align-items: center;
        border-bottom: $--border-light;
        > * {
          min-width: 0;
        }
        > .preview {
          flex: 1;
          ::v-deep {
            .variant-name {
              font-weight: $--font-weight-bold;
            }
          }
        }
        > .quantity {
          flex: 0 120px;
          @include media(xs-only) {
            flex: 1;
          }
        }
        @include media(xs-only) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
