import Vue from 'vue'
import VueTooltip from 'v-tooltip'
import AOS from 'aos'
import moment from 'moment'
import { isEqual, pick, mapValues } from 'lodash'
import { ARRAY_ITEM_DELIMITER, ARRAY_SUFFIX, DATE_FORMAT_ISO, PICKER_MAX_DAYS } from '~/settings'

import 'aos/dist/aos.css'
window.onNuxtReady(() => {
  AOS.init({
    duration: 500,
    once: true,
    mirror: false,
    disable: 'phone'
  })
})

Vue.use(VueTooltip, {
  defaultTrigger: 'hover focus click'
})

const parseTime = (time) => {
  const values = (time || '').split(':')

  if (values.length >= 2) {
    const hours = parseInt(values[0], 10)
    const minutes = parseInt(values[1], 10)

    return {
      hours,
      minutes
    }
  }

  return null
}

const getMinTime = (minTime, step = '00:30') => {
  if (!minTime) return

  const time = moment.duration(parseTime(minTime))
  // time.subtract(parseTime(step))

  return `${time.hours()}:${time.minutes()}`
}

const addSeparators = (nStr, thousandsSep, decimalSep) => {
  const x = String(nStr).split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? decimalSep + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1${thousandsSep}$2`)
  }
  return x1 + x2
}

export default ({ app, $dialogs, store, router, isDev, $moment }, inject) => {
  const elements = {
    cart: null
  }

  const utilities = {
    parseTime,
    getMinTime,
    setCartTarget (e) {
      elements.cart = e
    },
    scrollToTop (context) {
      if (context && typeof context.$nextTick === 'function') {
        context.$nextTick(() => {
          window.scrollTo(0, 0)
        })
      } else window.scrollTo(0, 0)
    },
    pickerOptionsDefaultRange: {
      disabledDate (date) {
        return $moment().add(PICKER_MAX_DAYS, 'days').isSameOrBefore(date)
      }
    },
    isEqualByKey (value1, value2, keys = []) {
      const c1 = pick(value1, keys)
      const c2 = pick(value2, keys)

      return isEqual(c1, c2)
    },
    pickerStartMonthDefaultValue: $moment().subtract(1, 'years').startOf('month').format(DATE_FORMAT_ISO),
    pickerStartDateDefaultValue: $moment().subtract(1, 'years').startOf('day').format(DATE_FORMAT_ISO),
    pickerStartDateDefaultRange: {
      disabledDate (date) { return $moment().subtract(1, 'years').startOf('day').isAfter(date) }
    },
    qval2val (val, key) {
      if (key.endsWith(ARRAY_SUFFIX)) {
        const value = val.split(ARRAY_ITEM_DELIMITER)
          .filter(i => i !== '')
          .map(i => /^\d+$/.test(i) ? +i : i)
        return value
      }
      if (/^\d+$/ig.test(val)) {
        if (!(val.startsWith('0') && val.length > 1)) {
          return +val
        }
      }
      return val
    },
    mapQueryString (query = {}) {
      return mapValues(query, this.qval2val)
    },
    numberFormat (optsIn) {
      const defaults = {
        digitsAfterDecimal: 2,
        scaler: 1,
        thousandsSep: ',',
        decimalSep: '.',
        prefix: '',
        suffix: ''
      }
      const opts = Object.assign({}, defaults, optsIn)
      return function (x) {
        if (isNaN(x) || !isFinite(x) || x === 0) {
          return ''
        }
        const result = addSeparators(
          (opts.scaler * x).toFixed(opts.digitsAfterDecimal),
          opts.thousandsSep,
          opts.decimalSep
        )
        return `${opts.prefix}${result}${opts.suffix}`
      }
    },
    getLatestDate  (array, key = 'eta_date') {
      if (!array?.length) return null

      return array.reduce((latest, current) => {
        if (!latest) return current[key]
        if (!current?.[key]) return latest

        return new Date(current[key]) > new Date(latest[key])
          ? current[key]
          : latest
      }, null)
    }
  }

  const preferences = {
    get: store.getters['preferences/byKey'],
    set: (key, value) => store.commit('preferences/update', { [key]: value }),
    update: (payload) => store.commit('preferences/update', payload),
    clear: key => store.commit('preferences/clear', key)
  }

  if (isDev) {
    Vue.prototype.$utilities = utilities
    app.$utilities = utilities
    store.$utilities = utilities

    Vue.prototype.$preferences = preferences
    app.$preferences = preferences
    store.$preferences = preferences
  }

  inject('utilities', utilities)

  inject('preferences', preferences)

  // if (aos) inject('aos', aos)
}
