<template lang="pug">
.nuxt-error.page.error(:data-code="error.statusCode")
  .wrapper
    .container
      empty(
        :title="title"
        :description="description"
        :type="type")
        .actions
          a.linked(href="#" @click.stop="back") ← Back
          nuxt-link.linked(:to="{ name: 'index' }") Homepage
          nuxt-link.linked(:to="toDashboard" v-if="toDashboard") Dashboard
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  scrollToTop: true,

  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },

  head () {
    return {
      title: this.title
    }
  },

  computed: {
    ...mapGetters({
      toDashboard: 'authentication/toDashboard'
    }),

    title () {
      switch (this.error.statusCode) {
        case 503:
          return 'Temporary Unavailable'
        case 403:
        case 404:
        default:
          return 'Uh-oh...'
      }
    },

    description () {
      switch (this.error.statusCode) {
        case 403:
          return 'You\'re not permitted to see this. The page you\'re trying to access has restricted access.'
        case 404:
          return 'Look like this page is missing...'
        case 503:
          return 'The page you\'re trying to access is temporary unavailable... Please try again later.'
        default:
          return 'Unexpected error...'
      }
    },

    type () {
      return this.error.statusCode
    }
  }
}
</script>

<style lang="scss" scoped>
.page.error {
  min-height: calc(80vh - 92px);
  display: flex;
  align-items: center;
  background: white;
  .wrapper {
    flex: 1;
  }
  .actions {
    text-align: center;
    > * {
      margin: .25em .75em;
    }
  }
}
</style>
