<template lang="pug">
aside.dashboard-side-bar(v-horizontal-scroll)
  .menu(v-if="isVendor")
    .item(v-if="showAllTabs || isStripeConnected")
      nuxt-link(:to="{name: 'vendors-analytics'}" v-click="() => $amplitude.track('CLICK_VENDOR_DASHBOARD_ANALYTICS_TAB')")
        i.icon.bx.bx-pie-chart-alt-2
        span.label Insights
    .item
      nuxt-link(:to="{name: 'vendors-orders'}")
        i.icon.bx.bx-cart-alt
        span.label Orders
    .item
      nuxt-link(:to="{name: 'vendors-samples'}")
        i.icon.bx.bxs-eyedropper
        span.label Samples
    .item(v-if="showAllTabs || isStripeConnected" ref="vendorProducts")
      el-popover(
        :ref="POPUP_REFS.VENDOR_PRODUCT"
        :placement="placement"
        trigger="manual"
        width="350"
        popper-class="el-popover--success onboarding-popover")
        .content Click here to create your first product and SKU.
        .tr
          el-button(type="text" @click="hideVendorOnboardingPopup(POPUP_REFS.VENDOR_PRODUCT)") Close
        nuxt-link(slot="reference" :to="{name: 'vendors-products', query: { page_source: 'product' }}")
          i.icon.bx.bx-copy
          span.label Products
    .item(v-if="hasInventoryInformation")
      nuxt-link(:to="{name: 'vendors-inventory'}")
        component(value="!" type="primary" :is="numberOfPodPlanned ? 'el-badge' : 'div'")
          i.icon.bx.bx-package
          span.label Inventory
    .item(v-if="showAllTabs || isStripeConnected" ref="vendorBrands")
      el-popover(
        :ref="POPUP_REFS.VENDOR_BRAND"
        :placement="placement"
        trigger="manual"
        width="350"
        popper-class="el-popover--success onboarding-popover")
        .content Click here to create your first brand.
        .tr
          el-button(type="text" @click="hideVendorOnboardingPopup(POPUP_REFS.VENDOR_BRAND)") Close
        nuxt-link(slot="reference" :to="{name: 'vendors-brands', query: { page_source: 'brand' }}")
          i.icon.bx.bx-copyright
          span.label Brands
    .item(v-if="!isBroker")
      nuxt-link(:to="{name: 'vendors-stores'}" v-click="() => $amplitude.track('CLICK_VENDOR_DASHBOARD_STORE_LIST_TAB')")
        i.icon.bx.bx-store-alt
        span.label Store List
    .item
      nuxt-link(:to="{name: 'vendors-payments'}" v-click="() => $amplitude.track('CLICK_VENDOR_DASHBOARD_PAYMENT_TAB')")
        //- img.custom-icon(v-if="isMobile && isFinanceTab" src="/img/icon.finance-white.svg")
        //- img.custom-icon(v-else src="/img/icon.finance-primary.svg")
        i.icon.bx.bx-calculator
        span.label Finance
    .item
      nuxt-link(:to="{name: 'vendors-promotions'}")
        i.icon.bx.bx-purchase-tag-alt
        span.label Promotions

    .item(v-if="!isBroker" ref="vendorWidgets")
      el-popover(
        :ref="POPUP_REFS.VENDOR_WHOLESALE_WIDGET"
        :placement="placement"
        trigger="manual"
        width="350"
        popper-class="el-popover--success onboarding-popover"
        title="Convert online traffic to wholesale orders.")
        .content Our wholesale widget links customers to your Pod brand page. Increase conversion rate. Delight your customers. Reduce friction. <br/> It's easy to add to your website!
        .tr
          el-button(type="text" @click="hideVendorOnboardingPopup(POPUP_REFS.VENDOR_WHOLESALE_WIDGET)") Close
        nuxt-link(
          :to="{name: 'vendors-widgets'}"
          slot="reference"
          v-click="() => $amplitude.track('CLICK_VENDOR_DASHBOARD_WIDGETS_TAB')")
          i.icon.bx.bxs-widget
          span.label Widgets

    .item
      nuxt-link(:to="{name: 'vendors-claims'}")
        i.icon.bx.bx-support
        span.label Claims
    .item(v-if="!isBroker")
      nuxt-link.noti-badge(:to="{name: 'vendors-notifications'}" v-click="() => $amplitude.track('CLICK_VENDOR_DASHBOARD_NOTIFICATIONS_TAB')")
        component(:is="unreadNotifications? 'el-badge': 'div'" :max="99" :value="unreadNotifications" type="primary" )
          i.icon.bx.bx-bell
          span.label Notifications
    .item(ref="vendorSettings")
      el-popover(
        :ref="POPUP_REFS.VENDOR_SETTING_PAYMENT"
        :placement="placement"
        trigger="manual"
        width="350"
        popper-class="el-popover--success onboarding-popover")
        .content Click here and visit the <strong>Payments</strong> page to complete <strong>Payout</strong> and <strong>Payments</strong> information.
        .tr
          el-button(type="text" @click="hideVendorOnboardingPopup(POPUP_REFS.VENDOR_SETTING_PAYMENT)") Close
        nuxt-link(slot="reference" :to="{name: 'vendors-settings'}")
          i.icon.bx.bx-cog
          span.label Settings

  .menu(v-if="isBuyer")
    .item
      nuxt-link(:to="{name: 'buyers-analytics'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_ANALYTICS_TAB')")
        i.icon.bx.bx-pie-chart-alt-2
        span.label Analytics
    .item
      nuxt-link(:to="{name: 'buyers-orders'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_ORDERS_TAB')")
        i.icon.bx.bx-cart-alt
        span.label Orders
    .item
      nuxt-link(:to="{name: 'buyers-samples'}")
        i.icon.bx.bxs-eyedropper
        span.label Samples
    .item
      nuxt-link(:to="{name: 'buyers-payments'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_PAYMENT_TAB')")
        i.icon.bx.bx-clipboard
        span.label Payments
    .item
      nuxt-link(:to="{name: 'buyers-settings'}")
        i.icon.bx.bx-cog
        span.label Settings

  .menu(v-if="isHeadBuyer")
    .item
      nuxt-link(:to="{name: 'head-buyer-analytics'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_ANALYTICS_TAB')")
        i.icon.bx.bx-pie-chart-alt-2
        span.label Analytics
    .item
      nuxt-link(:to="{name: 'head-buyer-orders'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_ORDERS_TAB')")
        i.icon.bx.bx-cart-alt
        span.label Orders
    .item
      nuxt-link(:to="{name: 'head-buyer-samples'}")
        i.icon.bx.bxs-eyedropper
        span.label Samples
    .item
      nuxt-link(:to="{name: 'head-buyer-payments'}" v-click="() => $amplitude.track('CLICK_BUYER_DASHBOARD_PAYMENT_TAB')")
        i.icon.bx.bx-clipboard
        span.label Payments
    .item
      nuxt-link(:to="{name: 'head-buyer-settings'}")
        i.icon.bx.bx-cog
        span.label Settings

  .menu(v-if="isLP")
    .item
      nuxt-link(:to="{name: 'lp-orders'}")
        i.icon.bx.bx-cart-alt
        span.label Orders
    .item(v-if="isWarehouseLP")
      nuxt-link(:to="{name: 'lp-inventory'}")
        i.icon.bx.bx-package
        span.label Inventory
    .item(v-if="isPodFreightLP")
      nuxt-link(:to="{name: 'lp-pod-freight'}")
        i.icon.bx.bxs-cube
        span.label Pod Freight
    .item
      nuxt-link(:to="{name: 'lp-settings'}")
        i.icon.bx.bx-cog
        span.label Settings
</template>

<script>
import { mapGetters } from 'vuex'
import { STORE_LIST_URL, DEBOUNCE_DELAY, POPUP_REFS } from '~/settings'
import { debounce } from 'lodash'

export default {
  data () {
    return {
      POPUP_REFS,
      STORE_LIST_URL,
      debouncedResize: () => {}
    }
  },

  async fetch () {
    try {
      await this.$nextTick()
      this.showVendorOnboardingPopup()
    } catch (e) {}
  },

  computed: {
    ...mapGetters({
      user: 'authentication/user',
      isBroker: 'authentication/isBroker',
      isVendor: 'authentication/isVendor',
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer',
      isLP: 'authentication/isLP',
      isWarehouseLP: 'authentication/isWarehouseLP',
      isPodFreightLP: 'authentication/isPodFreightLP',
      numberOfPodPlanned: 'master/numberOfPodPlanned',
      unreadNotifications: 'notifications/unreadNotifications',
      vendorCompanySelected: 'broker/vendorCompanySelected'
    }),

    isMobile () {
      if (!process.client) return false
      return window?.innerWidth < 768
    },

    isFinanceTab () {
      return this.$route.name?.includes('vendors-payments')
    },

    showAllTabs () {
      return this.isBroker
        ? this.vendorCompanySelected?.show_all_tabs
        : this.user?.show_all_tabs
    },

    hasInventoryInformation () {
      return this.isBroker
        ? this.vendorCompanySelected?.has_inventory_information
        : this.user?.has_inventory_information
    },

    isStripeConnected () {
      return this.isBroker
        ? this.vendorCompanySelected?.stripe_connected
        : this.user?.stripe_connected
    },

    placement () {
      return window.innerWidth > 768 ? 'right' : 'bottom'
    }
  },

  mounted () {
    this.debouncedResize = debounce(this.resize, DEBOUNCE_DELAY)
    this.$bus.$on('re-render-vendor-onboarding-popups', (data) => {
      this.showVendorOnboardingPopup(data?.disableScroll)
    })

    window.addEventListener('resize', this.debouncedResize)
    this.resize()
  },

  destroyed () {
    this.$bus.$off('re-render-vendor-onboarding-popups', this.showVendorOnboardingPopup)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.debouncedResize)
  },

  methods: {
    resize () {
      // Fix el-popover is wrong position when announcement bar is on
      window.dispatchEvent(new Event('resize'))
    },

    showVendorOnboardingPopup (disableScroll = false) {
      if (!this.isVendor || this.isBroker) return

      // Close first
      this.$refs[POPUP_REFS.VENDOR_SETTING_PAYMENT]?.doClose()
      this.$refs[POPUP_REFS.VENDOR_BRAND]?.doClose()
      this.$refs[POPUP_REFS.VENDOR_PRODUCT]?.doClose()
      this.$refs[POPUP_REFS.VENDOR_WHOLESALE_WIDGET]?.doClose()

      // Payment Popup
      const showPaymentPopup =
        this.$route.name !== 'vendors-settings' &&
        this.$route.name !== 'vendors-settings-payments' &&
        !this.isStripeConnected &&
        !this.user?.vendor_company_ach &&
        !this.$preferences.get(`$hide_${POPUP_REFS.VENDOR_SETTING_PAYMENT}`)
      if (showPaymentPopup) {
        this.$refs[POPUP_REFS.VENDOR_SETTING_PAYMENT]?.doShow()
        !disableScroll && this.$refs.vendorSettings?.scrollIntoView()
        return
      }

      // Brand Popup
      const showBrandPopup =
        !this.user?.has_brand &&
        this.$route.name !== 'vendors-brands' &&
        this.$route.name !== 'vendors-brands-id' &&
        !this.$preferences.get(`$hide_${POPUP_REFS.VENDOR_BRAND}`) &&
        (this.isStripeConnected || this.user?.vendor_company_ach)

      if (showBrandPopup) {
        this.$refs[POPUP_REFS.VENDOR_BRAND]?.doShow()
        !disableScroll && this.$refs.vendorBrands?.scrollIntoView()
        return
      }

      // Product Popup
      const showProductPopup =
        !this.user?.has_product &&
        this.user?.has_valid_brand &&
        this.$route.name !== 'vendors-products' &&
        !this.$preferences.get(`$hide_${POPUP_REFS.VENDOR_PRODUCT}`) &&
        (this.isStripeConnected || this.user?.vendor_company_ach)
      if (showProductPopup) {
        this.$refs[POPUP_REFS.VENDOR_PRODUCT]?.doShow()
        !disableScroll && this.$refs.vendorProducts?.scrollIntoView()
        return
      }

      // Wholesale Widget Popup
      const showWholesaleWidgetPopup =
        this.user?.has_product &&
        this.user?.has_valid_brand &&
        (this.isStripeConnected || this.user?.vendor_company_ach) &&
        !this.$preferences.get(`$hide_${POPUP_REFS.VENDOR_WHOLESALE_WIDGET}`)
      if (showWholesaleWidgetPopup) {
        this.$refs[POPUP_REFS.VENDOR_WHOLESALE_WIDGET]?.doShow()
        !disableScroll && this.$refs.vendorWidgets?.scrollIntoView()
      }
    },

    hideVendorOnboardingPopup (refName) {
      this.$refs[refName]?.doClose()
      this.$preferences.set(`$hide_${refName}`, true)
    }
  }
}
</script>
<style lang="scss">
.onboarding-popover {
  z-index: 2000 !important;
}
</style>
<style lang="scss" scoped>
aside.dashboard-side-bar {
  // background: linear-gradient(180deg, darken($--color-page-background, 4), transparent);
  margin-right: 0;
  background: white;
  overflow-x: auto;
  @include modern-scroller();
  @include media(xs-only) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: $--border-light;
  }
  @include media(sm) {
    background: darken($--color-page-background, 5);
    min-height: calc(100vh - 95px);
    border-right: $--border-light;
  }
  @include media(md) {
    overflow-x: hidden;
  }
  .menu {
    position: relative;
    display: flex;
    min-width: min-content;
    overflow: hidden;
    @include media(sm) {
      display: block;
    }
    .item {
      position: relative;
      min-width: 88px;
      &:first-child {
        a.nuxt-link-active {
          border-top-color: transparent;
        }
      }
      a {
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid transparent;
        padding: 6px 12px;
        height: 100%;
        @include media(sm) {
          margin-right: -1px;
          padding: 12px 10px;
        }
        &.nuxt-link-active {
          background: $--color-page-background;
          border-top-color: $--color-border-light;
          border-bottom-color: $--color-border-light;
          .icon {
            color: $--color-primary;
          }
          .label {
            color: $--color-text-regular;
          }
          @include media(xs-only) {
            background: $--color-primary;
            border-top-color: transparent;
            border-bottom-color: transparent;
            .icon {
              color: white;
            }
            .label {
              color: white;
            }
          }
        }
        .icon {
          color: $--color-primary;
          font-size: 1.75rem;
        }
        .custom-icon {
          width: 25px;
          height: 25px;
          margin-left: 4px;
        }
        .label {
          display: block;
          font-size: 12px;
          line-height: 1.25em;
          margin-top: .2em;
          flex: 1;
          @include media(sm) {
            font-weight: $--font-weight-bold;
          }
        }
      }

      .el-badge__content{
        top: 10px;
        right: 20px;
      }
    }
  }
}
.noti-badge {
  ::v-deep {
    .el-badge__content.is-fixed {
      transform: translateY(-50%) translateX(50%);
    }
  }
}
</style>
