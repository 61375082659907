import Vue from 'vue'
import NuxtJsonld from 'nuxt-jsonld'
import { WEBSITE_BASE_URL } from '~/settings'

Vue.use(NuxtJsonld)

export default ({ route }, inject) => {
  function head ({ title, type, description, properties, path, index = true, follow = true }) {
    const meta = [
      { hid: 'og:title', property: 'og:title', content: title },
      { hid: 'robots', property: 'robots', content: `${index ? 'index' : 'noindex'}, ${follow ? 'follow' : 'nofollow' }` }
    ]
    if (description) {
      meta.push({ hid: 'description', name: 'description', content: description })
      meta.push({ hid: 'og:description', property: 'og:description', content: description })
    }
    if (path) meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: `${WEBSITE_BASE_URL}${path}`
    })
    if (type) meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: type
    })
    if (properties) {
      Object
        .keys(properties)
        .forEach(key => {
          meta.push({ hid: key, property: key, content: properties[key] })
        })
    }

    return {
      title,
      description,
      meta
    }
  }

  function jsonld ({ type, title, description, images, sku, brand }) {
    const res = {
      '@context': 'http://schema.org',
      name: title,
      description,
      image: images
    }
    if (sku) res.sku = sku
    if (brand) res.brand = {
      '@type': 'Brand',
      name: brand.name
    }
    switch (type) {
      case 'product':
      case 'og:product':
        res.offers = {
          '@type': 'Offer',
          url: WEBSITE_BASE_URL + route.path,
          itemCondition: 'https://schema.org/UsedCondition',
          availability: 'https://schema.org/InStock',
          seller: {
            '@type': 'Organization',
            name: 'Pod'
          }
        }
    }

    return res
  }

  inject('seo', {
    head,
    jsonld
  })
}
