import { LIMIT_TYPES, SOCIAL_NETWORKS, VIMEO_REGEX, YOUTUBE_REGEX } from '~/settings'
import { cloneDeep } from 'lodash'

export const normalizeProductDetails = res => {
  if (!res.variants) return new Error('Variants is empty')

  Object
    .values(res.variants)
    .forEach(variant => {
      // add _moq into variant data
      if (variant.limit_type === LIMIT_TYPES.MOQ) {
        const moq = res.products_region_moqs.find(i => i.region_id === variant.region_id)
        variant._moq = moq ? moq.moq : 1
      }
      // make sure promotions is array
      variant._promotions = variant.promotions || []
    })
  return res
}

export const normalizeInactiveSku = (skus = {}) => {
  const newSkus = cloneDeep(skus)

  Object
    .values(newSkus)
    .forEach(sku => {
      sku.__INACTIVE = true
    })

  return newSkus
}

export const mapSyncers = (configs = {}) => {
  return Object.entries(configs).reduce((res, [key, prop]) => {
    res[key] = {
      get () {
        return this[prop]
      },
      set (v) {
        if (prop === 'value') this.$emit('input', v)
        else this.$emit(`update:${prop}`, v)
      }
    }
    return res
  }, {})
}

export const mapCloner = (configs = {}) => {
  return Object.entries(configs).reduce((res, [key, prop]) => {
    res[key] = {
      get () {
        return cloneDeep(this[prop])
      }
    }

    return res
  }, {})
}

export const mapSocialNetworks = (data = []) => {
  if (!data || !data.length) return cloneDeep(SOCIAL_NETWORKS)

  return SOCIAL_NETWORKS.map(e => {
    const nw = data.find(i => i.network === e.network)

    return { ...e, ...nw }
  })
}

export const mapVideoLinks = (data = []) => {
  if (!data || !data.length) return []

  return data.map(e => {
    const isVimeo = e.link.includes('vimeo')
    const youtubeRegex = YOUTUBE_REGEX
    const vimeoRegex = VIMEO_REGEX
    const match = e.link.match(isVimeo ? vimeoRegex : youtubeRegex)

    const videoId = match ? match[1] : null

    return {
      ...e,
      platform: isVimeo ? 'vimeo' : 'youtube',
      videoId,
      thumbnail: isVimeo ? `https://vumbnail.com/${videoId}.jpg` : `https://img.youtube.com/vi/${videoId}/0.jpg`,
      videoUrl: isVimeo ? `https://player.vimeo.com/video/${videoId}?autoplay=1` : `https://www.youtube.com/embed/${videoId}?autoplay=1`
    }
  })
}

export const validateVideoLink = (link = '') => {
  if (!link.startsWith('https://')) return false

  const youtubeId = !!link.match(YOUTUBE_REGEX)
  const vimeoId = !!link.match(VIMEO_REGEX)

  return youtubeId || vimeoId
}
