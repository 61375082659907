<template lang="pug">
.loading(:style="{ height: `${height}px` }")
  .loading--layout
    .loading--indicator(:style="{ width: size, height: size }")
    .loading--explaination(v-text="text" v-if="text")
</template>

<script>
export default {
  props: {
    height: {
      type: [Number],
      default: null
    },

    text: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: '2em'
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  font-size: 13px;
  padding: 10rem 0;
  display: block;
  width: 100%;
  &.no-padding {
    padding: 0;
  }
  &--layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > * {
      min-width: 0;
    }
  }
  &--indicator {
    display: block;
    background-image: url(/img/loading.svg?pod);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation: spin 1s linear infinite;
  }
  &--explaination {
    margin-left: 1em;
    color: $--color-text-secondary;
  }
}
</style>
