<template lang="pug">
.tracking-tag(:type="type") {{ transformType | capitalize }}
</template>

<script>
export default {
  name: 'TrackingTag',

  props: {
    type: {
      type: String,
      default: 'None'
    }
  },

  computed: {
    transformType () {
      switch (this.type) {
        case 'PRE_TRANSIT':
          return 'pre-transit'
        case 'TRANSIT':
          return 'in transit'
        default:
          return this.type
      }
    }
  }
}
</script>

<style lang="scss">
.tracking-tag {
  $symbol-size: 5px;
  border-radius: 3px;
  padding: 3px;
  display: inline-block;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: $--font-weight-bold;
  border: 1px solid #dcdfe6;
  &[type=UNKNOWN],
  &[type=FAILURE] {
    color: $--color-text-secondary;
    border-color: $--color-text-secondary;
  }
  &[type=INVALID] {
    visibility: hidden;
  }
  &[type=PRE_TRANSIT],
  &[type=TRANSIT] {
    color: $--color-primary;
    border-color: $--color-primary;
    background-color: $--color-primary-light
  }
  &[type=DELIVERED] {
    color: $--color-success;
    border-color: $--color-success;
    background-color: lighten($--color-success, 55)
  }
  &[type=RETURNED] {
    color: $--color-warning;
    border-color: $--color-warning;
    background-color: lighten($--color-warning, 50)
  }

}
</style>
