<template lang="pug">
  el-dialog.fulfillment-order-modal(
    ref="dialog"
    title="Confirm"
    visible
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="$emit('close')")

    .list-item
      .title Items
      .variant.flex(v-for="i, k in cItems" :key="k")
        .image
          .contain(v-image="i.image_url")
        .infor.ml-2 {{ i.product_variant_name }} &times; {{ i.quantity }}

    .delivery-methods.mt-2
      .title Delivery method
      el-form(
        ref="form"
        label-position="top"
        :model="form")
        el-form-item.boxed(label="Choose a delivery method" prop="shippingMethod")

          template(slot="label")
            help-tag(:tooltip="TOOLTIPS.deliveryMethodTooltip") Choose a delivery method

          el-select.pf-expanded(
            :disabled="type === FULFILLMENT_ORDER_TYPE.VIEW_SHIPPO_LABEL"
            v-model="form.deliveryMethod")
            el-option(label="Ship Direct to Store" :value="DELIVERY_METHODS.SHIP_DIRECT_TO_STORE")
            el-option(label="Self-deliver to Store" :value="DELIVERY_METHODS.SELF_DELIVER_TO_STORE")

        delivery-instruction(v-if="form.deliveryMethod" :order="cOrder" :type="form.deliveryMethod")

        // self delivery to store form
        template(v-if="form.deliveryMethod === DELIVERY_METHODS.SELF_DELIVER_TO_STORE")
          el-form.mt-3(
            ref="selfDeliveryForm"
            :model="selfDeliveryForm"
            :disabled="submitting"
            label-position="top"
            :rules="ownShippingRules")

            el-row(:gutter="30")
              el-col(:md="12")
                el-form-item.boxed(
                  label="Shipped Date"
                  prop="delivery_date")
                  el-date-picker.pf-expanded(
                    v-model="selfDeliveryForm.delivery_date"
                    type="date"
                    placeholder="MM/DD/YY"
                    :picker-options="PICKER_OPTIONS")

            div.notice
              .title Estimated arrival time
              .label.mb-2 Please indicate your estimated arrival time.

            el-row(:gutter="30")
              el-col(:md="12")
                el-form-item.boxed(
                  label="From"
                  prop="min_delivery_time")
                  el-time-select.pf-expanded(
                    v-model="selfDeliveryForm.min_delivery_time"
                    :value-format="timeValueFormat"
                    :picker-options="startTimeOptions"
                    placeholder="Select")
              el-col(:md="12")
                el-form-item.boxed(
                  label="To"
                  prop="max_delivery_time")
                  el-time-select.pf-expanded(
                    v-model="selfDeliveryForm.max_delivery_time"
                    :value-format="timeValueFormat"
                    :picker-options="endTimeOptions"
                    placeholder="Select")

            el-row(:gutter="30")
              el-col( :md="24")
                el-form-item.boxed(
                  prop="comment")
                  template(slot="label")
                    help-tag(:tooltip="`For special storage instructions, email ${ORDERS_EMAIL} to reach with questions, etc.`") Comments
                  el-input(
                    placeholder="Leave your comments here..."
                    type="textarea"
                    rows="3"
                    v-model="selfDeliveryForm.comment")

        // ship direct to store form
        template(
          v-if="form.deliveryMethod === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE && type !== FULFILLMENT_ORDER_TYPE.VIEW_SHIPPO_LABEL")
          .select-shipping-methods.mt-2
            .lable.mb-1 Choose a shipping method
            el-radio-group(v-model="form.shippingMethod")
              el-radio-button(:label="SHIPPING_METHODS.STANDARD") Use my own shipping label
              el-radio-button(:label="SHIPPING_METHODS.SHIPPO" :disabled="!shippoAddressValid")
                help-tag(v-if="shippoAddressValid" tooltip="You can buy a shipping label cheaper than the standard rate") Buy and Print shipping label
                help-tag(v-else tooltip="This option is not available for this store") Buy and Print shipping label

          // form standard shipping methods
          .standard-fulfillment-form.mt-3(v-if="form.shippingMethod === SHIPPING_METHODS.STANDARD")
            el-form(
              ref="standardForm"
              :model="standardForm"
              :disabled="submitting"
              label-position="top"
              :rules="ownShippingRules")

              el-row(:gutter="30")
                el-col(:md="12")
                  el-form-item.boxed(
                    label="Shipped Date"
                    prop="delivery_date")
                    el-date-picker.pf-expanded(
                      v-model="standardForm.delivery_date"
                      type="date"
                      placeholder="MM/DD/YY"
                      :picker-options="PICKER_OPTIONS")
                el-col(:md="12")
                  el-form-item.boxed(
                    label="Carrier"
                    prop="carrier")
                    el-select.pf-expanded(v-model="standardForm.carrier")
                      el-option(
                        v-for="i, k in carriers"
                        :key="k"
                        :label="i.name"
                        :value="i.id")

              el-row(:gutter="30")
                el-col
                  el-form-item.boxed(v-for="(item, k) in standardForm.manual_tracking_numbers" :key="k")
                    template(slot="label")
                      help-tag(:tooltip="TOOLTIPS.trackingNumberTooltip") Tracking number
                    el-input.tracking-number(type="text" v-model="standardForm.manual_tracking_numbers[k]")
                      template(#suffix v-if="k > 0")
                        a(href="#" @click.prevent="handleRemoveTrackingNumber(k)")
                          i.bx.bx-7.bx-x
                  .my-3
                    a.linked.flex.aic(href="#" rel="noopener" @click.prevent="handleAddTrackingNumber" )
                      i.bx.bx-5.bx-plus-circle.mr-1
                      | Add another tracking number

              el-row(:gutter="30")
                el-col( :md="24")
                  el-form-item.boxed(label="Comments" prop="comment")
                    el-input(
                      type="textarea"
                      placeholder="Leave your comments here..."
                      rows="3"
                      v-model="standardForm.comment")

          // form shippo shipping methods
          .shippo-shipping-methods(v-if="form.shippingMethod === SHIPPING_METHODS.SHIPPO")
            template(v-if="shippo_step === 1")
              el-form(
                ref="shippoForm"
                label-position="top"
                :model="shippoForm"
                :rules="shippoRules")
                h4 Parcel Information

                el-row(:gutter="30")
                  el-col(:md="8")
                    el-form-item.boxed(label="Width" prop="parcel_attributes.width")
                      el-input(type="number" v-model="shippoForm.parcel_attributes.width")
                  el-col(:md="8")
                    el-form-item.boxed(label="Height" prop="parcel_attributes.height")
                      el-input(type="number" v-model="shippoForm.parcel_attributes.height")
                  el-col(:md="8")
                    el-form-item.boxed(label="Length" prop="parcel_attributes.length")
                      el-input(type="number" v-model="shippoForm.parcel_attributes.length")
                  el-col(:md="8")
                    el-form-item.boxed(label="Weight" prop="parcel_attributes.weight")
                      el-input(type="number" v-model="shippoForm.parcel_attributes.weight")
                  el-col(:md="8")
                    el-form-item.boxed(label="Distance Unit" prop="parcel_attributes.distance_unit")
                      el-select.pf-expanded(v-model="shippoForm.parcel_attributes.distance_unit")
                        el-option(
                          v-for="i, k in shippoParcelValidDistanceUnits"
                          :key="k"
                          :label="i.name"
                          :value="i.id")
                  el-col(:md="8")
                    el-form-item.boxed(label="Mass Unit" prop="parcel_attributes.mass_unit")
                      el-select.pf-expanded(v-model="shippoForm.parcel_attributes.mass_unit")
                        el-option(
                          v-for="i, k in shippoParcelValidMassUnits"
                          :key="k"
                          :label="i.name"
                          :value="i.id")

                .flex.address-help-tag
                  h4 Address From
                  el-popover(
                    popper-class="popper-address-help-tag"
                    placement="top"
                    width="320")
                    div(v-html="TOOLTIPS.addressNotWorkTooltip.content")
                    span.reference(slot="reference") When your address doesn't work
                      i.bx.bxs-help-circle
                el-row(:gutter="30")
                  el-col(:md="12")
                    el-form-item.boxed(label="Name" prop="address_from_attributes.name")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.name")
                  el-col(:md="12")
                    el-form-item.boxed(label="Company" prop="address_from_attributes.company")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.company")
                  el-col(:md="12")
                    el-form-item.boxed(label="Phone number" prop="address_from_attributes.phone")
                      el-input(type="phone" v-model="shippoForm.address_from_attributes.phone")
                  el-col(:md="12")
                    el-form-item.boxed(label="Address street 1" prop="address_from_attributes.street1")
                      AddressAutocomplete(
                        :countries="['us', 'ca']"
                        v-model="shippoForm.address_from_attributes.street1"
                        @done="syncAddress")
                  el-col(:md="12")
                    el-form-item.boxed(label="Address street 2" prop="address_from_attributes.street2")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.street2")
                  el-col(:md="12")
                    el-form-item.boxed(label="City" prop="address_from_attributes.city")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.city")
                  el-col(:md="12")
                    el-form-item.boxed(label="Zipcode" prop="address_from_attributes.zip")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.zip")
                  el-col(:md="12")
                    el-form-item.boxed(label="State" prop="address_from_attributes.address_state_id")
                      address-state-select.db(v-model="shippoForm.address_from_attributes.address_state_id" @select="handleStateChange")
                  el-col(:md="12")
                    el-form-item.boxed(label="Country" prop="address_from_attributes.country")
                      el-select.pf-expanded(v-model="shippoForm.address_from_attributes.country")
                        el-option(
                          v-for="i, k in SHIPPO_COUNTRY_OPTIONS"
                          :key="k"
                          :label="i.name"
                          :value="i.id")
                  el-col(:md="12")
                    el-form-item.boxed(label="Email" prop="address_from_attributes.email")
                      el-input(type="text" v-model="shippoForm.address_from_attributes.email")

                h4 Address To

                el-row(:gutter="30")
                  el-col(:md="12")
                    el-form-item.boxed(label="Name" prop="address_to_attributes.name")
                      el-input(
                        type="text"
                        v-model="shippoForm.address_to_attributes.name")
                  el-col(:md="12")
                    el-form-item.boxed(label="Company" prop="address_to_attributes.company")
                      el-input(
                        type="text"
                        v-model="shippoForm.address_to_attributes.company")
                  el-col(:md="12")
                    el-form-item.boxed(label="Phone number" prop="address_to_attributes.phone")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.phone")
                  el-col(:md="12")
                    el-form-item.boxed(label="Address street 1" prop="address_to_attributes.street1")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.street1")
                  el-col(:md="12")
                    el-form-item.boxed(label="Address street 2" prop="address_to_attributes.street2")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.street2")
                  el-col(:md="12")
                    el-form-item.boxed(label="City" prop="address_to_attributes.city")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.city")
                  el-col(:md="12")
                    el-form-item.boxed(label="Zipcode" prop="address_to_attributes.zip")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.zip")
                  el-col(:md="12")
                    el-form-item.boxed(label="State" prop="address_to_attributes.state")
                      el-input(
                        disabled
                        type="text"
                        v-model="shippoForm.address_to_attributes.state")
                  el-col(:md="12")
                    el-form-item.boxed(label="Country" prop="address_to_attributes.country")
                      el-select.pf-expanded(disabled v-model="shippoForm.address_to_attributes.country")
                        el-option(
                          v-for="i, k in SHIPPO_COUNTRY_OPTIONS"
                          :key="k"
                          :label="i.name"
                          :value="i.id")

            // choose shippo label
            template(v-else)
              h4 Please Select A Rate:
               el-alert.warning.mb-1(
                :closable="false"
                type="warning"
                v-if="shippo_rates.length > 0")
                .note
                  span Once you purchase a shipping label, you will not be able to change the delivery method. Please contact at&nbsp;
                  a.linked(:href="`mailto:${ORDERS_EMAIL}`") {{ ORDERS_EMAIL }}&nbsp;
                  span if you need help.
              .shippo-grid(v-if="shippo_rates.length > 0")
                .edt-row(v-for="i, k in shippo_rates" :key="k")
                  .edt-piece.radio
                    el-radio(v-model="rate" :label="i.object_id") &nbsp;
                    .contain(v-image="i.provider_image_75")
                  .edt-piece.details.pf-break-word
                    strong
                      span {{ i.provider }}
                      span.mx-1(v-for="i, k in i.attributes")
                        shippo-attribute-type(:type="i")
                    p(v-if="i.duration_terms") {{ i.duration_terms }}
                  .edt-piece.price {{ i.amount }} {{ i.currency }}
                  el-alert.edt-piece.note.warning.mb-1(
                    :closable="false"
                    type="warning"
                    v-if="showUSPSNote && rate === i.object_id")
                    .note Pod will automatically refund any unused USPS labels 30 days after purchase
              template(v-else)
                p.rb-note There are no rates found

        // View shippo rate when mode view
        template(
          v-if="form.deliveryMethod === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE && type === FULFILLMENT_ORDER_TYPE.VIEW_SHIPPO_LABEL")
          view-shippo-rate.mt-2(:shippo="shippoLabel")

        // confirm button
        .modal-footer
          template(
            v-if="form.shippingMethod === SHIPPING_METHODS.STANDARD || form.deliveryMethod == DELIVERY_METHODS.SELF_DELIVER_TO_STORE")
            el-button.mt-2(
              @click="submit"
              size="medium"
              type="primary"
              :loading="submitting") Confirm
          template(v-else-if="form.shippingMethod === SHIPPING_METHODS.SHIPPO")
            el-button.mt-2(
              v-if="shippo_step === 1"
              type="primary"
              :loading="submitting"
              @click="submitShippoForm") Get Rates
            div(v-else)
              el-button.mt-3(
                type="primary"
                plain
                @click="onBackStep") Back
              el-button.mt-3(
                :disabled="!rate"
                type="primary"
                :loading="submitting"
                @click="submit") Buy

</template>
<script>
import { mapGetters } from 'vuex'
import { pickBy, isPlainObject } from 'lodash'
import moment from 'moment'

import { mapCloner } from '~/utilities/normalizers'
import DeliveryInstruction from '~/components/DeliveryInstruction'
import ViewShippoRate from '~/components/samples/ViewShippoRate'
import HelpTag from '~/components/tags/HelpTag'
import ShippoAttributeType from '~/components/tags/ShippoAttributeTag'
import AddressAutocomplete from '~/components/inputs/AddressAutocomplete'
import { SHIPPO_ADDRESS_STATE, SHIPPO_COUNTRY_OPTIONS, FULFILLMENT_ORDER_TYPE, DELIVERY_METHODS, PICKER_MAX_DAYS, ORDERS_EMAIL } from '~/settings'

const SHIPPING_METHODS = {
  STANDARD: 'standard',
  SHIPPO: 'shippo'
}

const TOOLTIPS = {
  deliveryMethodTooltip: {
    content: 'Pod Consignment : Add-on service to store product on consignment (i.e., you retain ownership) in our distribution center(s).<br/> Ship Direct to Store : Order will be shipped directly to the store.<br/> Self-Deliver to Store : Order will be self-delivered to the store.',
    classes: 'large'
  },

  trackingNumberTooltip: {
    // eslint-disable-next-line
    content: `VERY IMPORTANT. We use this to follow up on your items, plan our routes, and communicate ETAs to buyers.<br /><strong> If not entered, we'll assume you're not fulfilling and remove your items from the order</strong>.<br />You can edit your tracking number after it's been confirmed.`,
    classes: 'large'
  },

  addressNotWorkTooltip: {
    // eslint-disable-next-line
    content: `You can troubleshoot the address directly on the USPS website. You can use this link: <a href='https://tools.usps.com/go/ZipLookupAction_input' class='linked' target='_blank' rel='noopener noreferrer'>https://tools.usps.com/go/ZipLookupAction_input</a>. If your address still doesn&apos;t work, try Googling the address, and check how the address appears in Google Maps.`,
  }
}

export default {
  components: {
    DeliveryInstruction,
    ViewShippoRate,
    HelpTag,
    ShippoAttributeType,
    AddressAutocomplete
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    invoice: {
      type: Object,
      default: () => {}
    },

    order: {
      type: Object,
      default: () => {}
    },

    type: {
      type: String,
      default: ''
    }
  },

  data () {
    const today = moment().date()
    const month = moment().startOf('month')
    const prevMonth = moment().add(-1, 'months').startOf('month')
    const max = moment().add(PICKER_MAX_DAYS, 'days')

    return {
      ORDERS_EMAIL,
      TOOLTIPS,
      SHIPPING_METHODS,
      DELIVERY_METHODS,
      FULFILLMENT_ORDER_TYPE,
      SHIPPO_COUNTRY_OPTIONS,
      submitting: false,
      timeValueFormat: 'HH:mm',
      form: {
        deliveryMethod: null,
        shippingMethod: null
      },
      standardForm: {
        manual_tracking_numbers: ['']
      },
      selfDeliveryForm: {},
      shippo_rates: [],
      rate: null,
      shippoForm: {
        parcel_attributes: {},
        address_to_attributes: {
          name: '', // must initialize value
          company: ''// must initialize value
        },
        address_from_attributes: {}
      },
      shippo_step: 1,
      shippoLabel: {},
      PICKER_OPTIONS: {
        disabledDate (date) {
          // locked statement
          return (today >= 6 ? date < month : date < prevMonth) || max.isSameOrBefore(date)
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      shippoParcelValidDistanceUnits: 'master/shippoParcelValidDistanceUnits',
      shippoParcelValidMassUnits: 'master/shippoParcelValidMassUnits',
      carriers: 'master/carriers',
      getStateById: 'master/getStateById',
      getCountryId: 'master/getCountryId'
    }),

    ...mapCloner({
      cItems: 'items',
      cInvoice: 'invoice',
      cOrder: 'order'
    }),

    countryId () {
      if (!this.cOrder.address_attributes) return
      return this.getStateById(this.cOrder.address_attributes.address_state_id).country_id
    },

    countryName () {
      if (!this.cOrder.address_attributes) return
      return this.getCountryId(this.countryId).name
    },

    ownShippingRules () {
      const { required } = this.$validators
      return { delivery_date: [required] }
    },

    shippoRules () {
      const { required, email } = this.$validators

      return {
        'parcel_attributes.width': [required],
        'parcel_attributes.height': [required],
        'parcel_attributes.length': [required],
        'parcel_attributes.weight': [required],
        'parcel_attributes.distance_unit': [required],
        'parcel_attributes.mass_unit': [required],
        'address_from_attributes.name': [required],
        'address_from_attributes.street1': [required],
        'address_from_attributes.city': [required],
        'address_from_attributes.zip': [required],
        'address_from_attributes.state': [required],
        'address_from_attributes.country': [required],
        'address_from_attributes.email': [email],
        'address_to_attributes.name': [required]
      }
    },

    showUSPSNote () {
      const rateSelected = this.shippo_rates.find(e => e.object_id === this.rate)

      return rateSelected?.provider === 'USPS'
    },

    startTimeOptions () {
      return {
        start: '00:00',
        end: '23:30'
      }
    },

    endTimeOptions () {
      return {
        start: '00:00',
        end: '23:30',
        minTime: this.$utilities.getMinTime(this.selfDeliveryForm.min_delivery_time)
      }
    },

    shippoAddressValid () {
      return this.cOrder.address_attributes.shippo_address_state === SHIPPO_ADDRESS_STATE.VALID
    }

  },

  watch: {
    'form.deliveryMethod': {
      handler (val) {
        if (val === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE)
          this.scrollBoottom()
        if (this.form.shippingMethod === SHIPPING_METHODS.SHIPPO && val === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE)
          this.scrollBoottom(300)
      }
    },

    'form.shippingMethod': {
       handler (val) {
        if (val === SHIPPING_METHODS.SHIPPO)
          this.scrollBoottom(300)
        else this.scrollBoottom()
      }
    }
  },

  created () {
    this.initDataShippo()
    if (this.type !== FULFILLMENT_ORDER_TYPE.CREATE) {
      this.initDataSubInvoice()
    }
  },

  methods: {
    handleRemoveTrackingNumber (index) {
      this.standardForm.manual_tracking_numbers = this.standardForm.manual_tracking_numbers.filter((e, i) => i !== index)
    },

    handleAddTrackingNumber () {
      this.standardForm.manual_tracking_numbers.push('')
    },

    handleStateChange (state) {
      this.$set(this.shippoForm.address_from_attributes, 'state', state.name)
    },

    syncAddress (data) {
      this.shippoForm = {
        ...this.shippoForm,
        address_from_attributes: {
          ...this.shippoForm.address_from_attributes,
          ...data,
          state: data.address_state?.name
        }
      }
    },

    submit () {
      const { deliveryMethod, shippingMethod } = this.form
      let formToValidate = null

      if (deliveryMethod === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE) {
        formToValidate = this.$refs.standardForm
      }
      if (deliveryMethod === DELIVERY_METHODS.SELF_DELIVER_TO_STORE) {
        formToValidate = this.$refs.selfDeliveryForm
      }
      this.$validate(formToValidate).then(ok => {
        if (!ok) return
        this.submitting = true
        const params = {
          order_id: this.$route.params.id,
          delivery_method: deliveryMethod,
          sub_invoice: {
            line_item_ids: this.cItems.map(i => i.id),
            line_items_attributes: this.cItems.map(({ id, expiry_date }) => ({ id, expiry_date }))
          }
        }

        // choose form to send request
        if (deliveryMethod === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE && shippingMethod === SHIPPING_METHODS.STANDARD) {
          params.deliverable = {
            ...this.standardForm,
            manual_tracking_numbers: this.standardForm.manual_tracking_numbers.filter(e => !!e.trim())
          }
        } else if (deliveryMethod === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE && shippingMethod === SHIPPING_METHODS.SHIPPO) {
          params.rate = this.rate
        } else {
          params.deliverable = this.selfDeliveryForm
        }

        const isCreate = this.type === FULFILLMENT_ORDER_TYPE.CREATE
        const modifyParams = pickBy(params, (value, _) => isPlainObject(value) ? Object.keys(value).length > 0 : true)

        if (!isCreate) modifyParams.id = this.cInvoice.id

        const service = isCreate
          ? this.$api.vendor.createPodDirectConfirmation
          : this.$api.vendor.updatePodDirectConfirmation
        const message = isCreate
          ? 'Delivery information updated successfully! Please print invoice & packing slip!'
          : 'Delivery information updated successfully!'

        service(modifyParams)
          .then((res) => {
            this.$workflow.notify(message)
            this.$bus.$emit('reset-data-vendor-order-detail')
            this.$emit('close')

            if (shippingMethod === SHIPPING_METHODS.STANDARD) {
              this.$amplitude.track('SUBINVOICE_SET_SHIPPED_DATE', {
                shipped_date: res.deliverable?.delivery_date,
                checkout_date: this.cOrder.checkout_date,
                order_id: this.cOrder.id,
                sub_invoice_id: res.id
              })
            }
          })
          .finally(() => {
            this.submitting = false
          })
      })
    },

    submitShippoForm () {
      this
        .$validate(this.$refs.shippoForm)
        .then(ok => {
          if (!ok) return
          this.submitting = true

          this.$api.vendor.createShippoShipment({ shipment: this.shippoForm })
            .then(({ shipment }) => {
              this.shippo_step = 2
              this.shippo_rates = shipment.rates
            })
            .finally(() => {
              this.submitting = false
            })
          })
    },

    initDataShippo () {
      if (!this.cOrder) return
      this.shippoForm.address_to_attributes.company = this.cOrder.address_attributes.company
      this.shippoForm.address_to_attributes.street1 = this.cOrder.address_attributes.street1
      this.shippoForm.address_to_attributes.street2 = this.cOrder.address_attributes.street2
      this.shippoForm.address_to_attributes.city = this.cOrder.address_attributes.city
      this.shippoForm.address_to_attributes.zip = this.cOrder.address_attributes.zip
      this.shippoForm.address_to_attributes.state = this.cOrder.address_attributes.address_state_name
      this.shippoForm.address_to_attributes.name = this.cOrder.store_name
      this.shippoForm.address_to_attributes.email = this.cOrder.buyer_email
      this.shippoForm.address_to_attributes.attn = this.cOrder.address_attributes.attn
      this.shippoForm.address_to_attributes.phone = this.cOrder.store_phone_number
      if (this.countryName === 'U.S') {
        this.shippoForm.address_to_attributes.country = 'US'
      } else if (this.countryName === 'Canada') {
        this.shippoForm.address_to_attributes.country = 'CA'
      }

      if (this.cOrder.address_attributes?.shippo_address_state === SHIPPO_ADDRESS_STATE.UNVERIFIED) {
        this.$api.vendor.validateShippoAddress({
          id: this.cOrder.address_attributes.id,
          ...this.shippoForm.address_to_attributes
        }).then(({ shippo_address_state }) => {
          this.$set(this.cOrder.address_attributes, 'shippo_address_state', shippo_address_state)
        })
      }
    },

    initDataSubInvoice () {
      if (!this.cInvoice) return
      if (this.type === FULFILLMENT_ORDER_TYPE.UPDATE_POD_DIRECT_STANDARD) {
        // eslint-disable-next-line camelcase
        this.form.deliveryMethod = this.cInvoice.deliverable?.delivery_type
        this.form.shippingMethod = SHIPPING_METHODS.STANDARD
        this.standardForm = this.cInvoice.deliverable || { manual_tracking_numbers: [''] }
      } else if (this.type === FULFILLMENT_ORDER_TYPE.UPDATE_SELF_DELIVER_TO_STORE) {
        // eslint-disable-next-line camelcase
        this.form.deliveryMethod = this.cInvoice.deliverable?.delivery_type
        this.selfDeliveryForm = this.normalizeTimeFormat(this.cInvoice.deliverable)
      } else if (this.type === FULFILLMENT_ORDER_TYPE.VIEW_SHIPPO_LABEL) {
        // eslint-disable-next-line camelcase
        this.form.deliveryMethod = this.cInvoice.deliverable?.delivery_type
        // eslint-disable-next-line camelcase
        this.shippoLabel = this.cInvoice.deliverable?.shippo_labels[0]
      }
    },

    normalizeTimeFormat (data) {
      return {
        ...data,
        min_delivery_time: this.$options.filters.dateWithoutTimezone(data.min_delivery_time, this.timeValueFormat),
        max_delivery_time: this.$options.filters.dateWithoutTimezone(data.max_delivery_time, this.timeValueFormat)
      }
    },

    scrollBoottom (space = 0) {
      this.$nextTick(() => {
        const dialog = this.$el
        dialog.scrollTo({
          top: dialog.scrollHeight - window.innerHeight - space,
          left: 0,
          behavior: 'smooth'
        })
      })
    },

    onBackStep () {
      this.shippo_step = 1
      this.rate = null
    }
  }
}
</script>
<style lang="scss" scoped>
.fulfillment-order-modal {
  .image {
    flex: 0 64px;
    padding: 6px;
    .contain {
      width: 100%;
      height: 0;
      padding-top: 100%;
      border-radius: $--radius;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  ::v-deep {
    .el-alert {
      &__description {
        margin-top: 0;
      }
    }

    .el-input.tracking-number {
      .el-input__suffix {
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .title {
    font-weight: 800;
    font-size: 14px;
    color: #111111;
    margin-bottom: 10px;
  }
  .variant {
    align-items: center;
  }
  .modal-footer {
    text-align: right;
    .el-button {
      padding: 1.3rem 2.2em;
    }
  }
  .address-help-tag {
    justify-content: space-between;
    align-items: center;
    cursor: help;
    span {
      & > i.bx {
        color: $--color-primary;
        vertical-align: middle;
        margin-left: .5em;
      }
    }
  }
}

.edt-piece {
  &.radio {
    grid-area: radio;
    flex-direction: row;
    .contain {
      display: block;
      border-radius: $--radius;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-top: 15px;
    }
  }
  &.details { grid-area: details; }
  &.price {
    grid-area: price;
    color: $--color-primary;
    font-weight: $--font-weight-bold;
  }
  &.note {
    grid-area: note;
  }
}
.edt-row {
  grid-template-areas:
    'radio details price'
    'note note note';
  grid-template-columns: 1fr 3fr 1fr;
}

</style>
<style lang="scss">
.popper-address-help-tag {
  background: black;
  color: white;
  border-radius: 4px;
  padding: 10px 10px 8px;
  font-size: 12px;
  line-height: 1.5em;
  border: none;
  .popper__arrow {
    &::after {
      border-top-color: black !important;
    }
  }
}
</style>
