<template lang="pug">
#app.layout.layout--landing
  announcements-bar
  landing-header-bar

  #content
    nuxt

  landing-footer-bar
</template>

<script>
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import LandingHeaderBar from '~/components/menus/LandingHeaderBar'
import LandingFooterBar from '~/components/menus/LandingFooterBar'

export default {
  name: 'LandingLayout',

  components: {
    AnnouncementsBar,
    LandingHeaderBar,
    LandingFooterBar
  },

  head () {
    return {
      bodyAttrs: {
        class: 'pod-2025'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.layout {
  background: white;
}
</style>
