<template lang="pug">
el-dialog(
  custom-class="page__dialog consignment-instructions-modal"
  visible
  :show-close="false"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bx-package

    .page__dialog-info.flex.aic
      .page__dialog-title General Instructions

  .instructions
    p Withdrawal lead time is 5 business days from the date you submit this form. Each withdrawal request requires a pickup appointment.
    p Pickup appointments are subject to availability and Pod reserves the right to decline withdrawal requests if too frequent or the amount of inventory in stock does not allow for withdrawal.
    p Fees apply:
      br
      | $0.85/case (Min. Charge of $70) for all withdrawals made at least 24 hours prior to the product's pull date.
      br
      | $0.90/case (Min Charge of $100) for all withdrawals made within 24 hours of, or after the product's pull date.

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="submit") Confirm
</template>

<script>
import { BRAND_GUIDE_URL } from '~/settings'

export default {
  data () {
    return {
      BRAND_GUIDE_URL
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },

  methods: {
    submit () {
      this.$emit('done')
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .consignment-instructions-modal {
    max-width: 600px;
  }
}
.page__dialog-icon {
  color: $--color-warning;
  background: lighten($--color-warning, 40);
}
.instructions {
  ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
    > li {
      &:not(:last-child) {
        margin-bottom: .5em;
      }
    }
  }
  ul {
    margin-top: .5em;
  }
  .yellow {
    font-weight: $--font-weight-bold;
    color: $--color-warning;
  }
  .important {
    font-weight: $--font-weight-bold;
    color: $--color-primary;
  }
  .italic {
    font-style: italic;
  }
  a.linked {
    font-weight: $--font-weight-bold;
    text-decoration: underline;
  }
}
</style>
